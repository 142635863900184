/* ==============================
    Forms
   ============================== */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

::-webkit-input-placeholder {
  color: #555;
}

:-moz-placeholder { /* Firefox 18- */
  color: #555;
}

::-moz-placeholder {  /* Firefox 19+ */
  color: #555;
}

:-ms-input-placeholder {
  color: #555;
}

.placeholder {
  color: #555;
}

:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-ms-clear {
  display: none;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: none;
  font-size: 12px;
  line-height: 18px;
  outline: none;
  padding: $margin*2 $margin*3;

  &::placeholder {
    font-size: 12px;
    line-height: 18px;
  }
}


.custom-checkbox {
  position: relative;

  input {
    cursor: pointer;
    display: none;
    height: initial;
    margin-bottom: 0;
    padding: 0;
    width: initial;
  }

  label {
    cursor: pointer;
    padding-left: 20px;
    position: relative;

    &::before {
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid white;
      box-shadow: none;
      content: '';
      cursor: pointer;
      display: inline-block;
      left: 0;
      margin-right: 5px;
      padding: 6px;
      position: absolute;
      top: -3px;
      vertical-align: middle;
    }
  }
}




.custom-checkbox input:checked + label::after {
  border: solid white;
  border-width: 0 2px 2px 0;
  content: "";
  display: block;
  height: 6px;
  left: 5px;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  width: 2px;
}
