/* TODO */
@mixin transition($type:all,$time:0.36s,$ease:cubic-bezier(0.25, 1, 0.5, 1),$delay:0s) {
  -webkit-transition: $type $time $ease;
  -moz-transition: $type $time $ease;
  -ms-transition: $type $time $ease;
  -o-transition: $type $time $ease;
  transition: $type $time $ease;
  transition-delay: $delay;
}

.anim-svg {
  position: relative;

  svg {
    position: absolute;
    transform: translate(-50%, -50%);
  }

  &.svg-circle {
    svg {
      height: 140%;
      left: 60%;
      top: 50%;
    }
  }

  &.svg-line {
    svg {
      bottom: -30%;
      height: auto;
      left: 50%;
      width: 110%;
    }
  }

  &.svg-bounce {
    svg {
      bottom: -50%;
      height: auto;
      left: 50%;
      width: 110%;
    }
  }
}

.link_anim {
  position: relative;

  span {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateY(100%) translateX(-50%);

    svg {
      max-width: 85px;
    }
  }
}

.scroll-show-left {
  transform: translateX(-100%);
}

.scroll-show-right {
  transform: translateX(100%);
}

.scroll-scale {
  transform: scale(0);
}

.scroll-show-bottom {
  opacity: 0;
  transform: translateY(50px);
}

.scroll-to-top {
  @include teq-breakpoint($large) {
    transform: translateY(200px);
  }
}

.deco {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  >div {
    position: absolute;

    &:first-child {
      left: 0;
      max-width: 160px;
      top: 50%;
      transform: translateY(-50%);
      width: 20%;
    }

    &:nth-child(2) {
      max-width: 160px;
      right: 0;
      top: 0;
      width: 20%;
    }
  }
}
