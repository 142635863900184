.block-slider-history {
  margin-bottom: $margin*2;
  margin-top: $margin*2;

  @include teq-breakpoint($large) {
    margin-bottom: $margin*9;
    margin-top: $margin*9;
  }


  .swiper-slider-history {
      padding-bottom: 20px;
    @include teq-breakpoint($large) {
      margin-top: $margin*5;
        padding-bottom: 0px;
    }
      .swiper-wrapper{
          align-items: stretch;
      }

    .swiper-slide {
      padding: $margin*4 $margin*2;

      @include teq-breakpoint($large) {
        padding: 0;
      }

      .title-mobile {
        letter-spacing: 1px;
        margin-bottom: 0 !important;

        @include teq-breakpoint($large) {
          display: none;
        }

        svg {
          margin-bottom: 0;
          margin-top: $margin*3;
        }
      }

      >div {
        height: 100%;
      }

      .slide-text {
        flex: 0 0 100%;
        padding-right: 18px;
        width: 100%;
          @include teq-breakpoint($large){
              transform: translateY(0px);
          }
        @include teq-breakpoint(0, $large) {

          svg {
            display: none;
          }
        }

        .text {
          opacity: 0;
        }

        @include teq-breakpoint($large) {
          display: none;
          flex: 0 0 50%;
          width: 50%;
        }

        >.title-32 {
          display: none;

          @include teq-breakpoint($large) {
            display: block;
          }
        }

        .txt-22 {
          @include teq-breakpoint(0, $large) {
            font-size: 14px !important;
            line-height: 20px;
          }
        }
      }

      .slide-imgs {
        margin-bottom: $margin*2;
        position: relative;
        width: 100%;

        @include teq-breakpoint($large) {
          margin-bottom: 0;
          padding-right: calc(100% / 11.5);
          width: 33.3333%;
        }


        .slide-imgs-item {
          overflow: hidden;
          position: relative;
          width: 100%;

          @include teq-breakpoint($large) {
            width: 50%;
          }


          &::after {
            background-color: white;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            right: -2px;
            top: 0;
            width: 0;

            @include transition(all,1s);
          }

          &:first-child {
            display: flex;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;

            img {
              transform: scale(1.4);
            }
          }

          &:nth-child(2) {
            margin-bottom: 5%;
            margin-left: 10%;
            margin-top: 5%;
            width: 90%;

            @include teq-breakpoint($large) {
              margin-bottom: 7%;
              margin-left: 20%;
              margin-top: 7%;
              width: 80%;
            }

            &::after {
              background-color: #e7d3d3;
            }
          }

          &.transition {
            &::after {
              height: 100%;
              width: calc(100% + 4px);
            }
          }
        }

        img {
          margin-left: -1px;
          object-fit: cover;
          width: 100%;

          @include transition(all,1s);
        }
      }

      &.swiper-slide-prev {
        >div {
          justify-content: flex-end;
        }
      }

      &.swiper-slide-active {
        .slide-text {
          display: block;
        }

        .slide-imgs {
          @include teq-breakpoint($large) {
            width: 50%;
          }

          .slide-imgs-item:first-child img {
            transform: scale(1);
          }
        }
      }
    }
  }

  .swiper-slider-history-nav {
    ;
    left: 50%;
    pointer-events: none;
    position: relative;
    transform: translate(-50%, 0%);
    width: auto;
    z-index: 10;

    @include teq-breakpoint($large) {
     // bottom: -2%;
      transform: none;
     // width: 50%;
    }

    >div {
      pointer-events: all;

      &.count {
        margin: 0 $margin;
        white-space: nowrap;

        span:nth-child(2) {
          margin: 0 $margin;
        }
      }

      &:not(.count) {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

