.block-title-full {
  margin-bottom: $margin*2;
  margin-top: $margin*2;
  position: relative;
    @include teq-breakpoint(0,$large) {
        text-align: center;
    }
  @include teq-breakpoint($large) {
    margin-bottom: $margin*9;
    margin-top: $margin*9;
  }

  .illu {
    display: none;
    position: absolute;
    right: 0;
    top: 20%;
    transform: translateY(-50%);
    width: auto;

    @include teq-breakpoint($large) {
      display: block;
    }
  }

  h4 {
    @include teq-breakpoint(0, $large) {
      br {
        &:first-child {
          display: none !important;
        }
      }
    }
  }

  .text {
    margin: 0 auto $margin*2;
    max-width: 660px;
  }
}
