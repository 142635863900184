/* ==============================
    Global
   ============================== */
html,
body {
  background: #fff;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

body:not(.loading) {
  scroll-behavior: smooth;
}

html {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

body {
  position: relative;
  scroll-behavior: smooth;
}

.global {
  min-height: 100%;
  overflow: hidden;
  // transition-duration: 0.6s;
  padding-top: 96px;
  position: relative;

  @include teq-breakpoint($large) {
    padding-top: 112px;
  }
}

.img-fluid {
  width: 100%;
}
