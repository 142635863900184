$dark : #000;
$grey: #c4c4c4;
$grey-bg:#f1f1f1;
$txt-grey:#757474;
$txt-grey-light:#e1e1e1;
$grey-border:#ddd;
$brown : #c58467;

$pink:#f0c7b9;

$green-bg:#447950;

.txt-brown {
  color: $brown;
}

.txt-grey {
  color: $txt-grey;
}
