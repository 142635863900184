#cp-menu {
  background-color: white;
  border-bottom: 1px solid $grey-border;
  left: 0;
  padding: $margin*3 $margin*10;
  position: fixed;
  top: 0;
  transform: translateY(-100%);
  width: 100%;
  z-index: 10;

  @include transition();

  >div {
    @include teq-breakpoint(0,$large) {
      justify-content: center !important;
    }
  }

  .logo-container {
    svg {
      display: block;
      height: 47px;
      margin: 0 auto;
    }
  }

  .nav-container {
    background-color: black;
    height: 100vh;
    left: 0;
    padding-top: 135px;
    position: fixed;
    top: 0;
    transform: translateX(100%);
    width: 100%;
    z-index: -1;

    @include teq-breakpoint(0,$large) {
      @include transition();

      opacity: 0;
    }

    @include teq-breakpoint($large) {
      background-color: transparent;
      height: auto;
      left: auto;
      padding-top: 0;
      position: relative;
      top: auto;
      transform: none;
      width: auto;
      z-index: 1;
    }

    .nav {
      padding-left: 0;

      li {
        text-align: center;
        width: 100%;

        @include teq-breakpoint($large) {
          margin: 0 $margin*3;
          width: auto;
        }

        a {
          color: white;
          text-transform: uppercase;

          @include teq-breakpoint($large) {
            color: black;
          }
        }
      }
    }
  }

  .nav-switcher {
    margin-left: $margin*4;

    span {
      background-color: $dark;
      content: "";
      display: block;
      height: 20px;
      margin: 0 $margin;
      width: 1px;
    }

    a {
      &.active {
        color: $brown;
        pointer-events: none;
      }
    }
  }

  .burger-mobile {
    position: absolute;
    right: $margin*2;
    top: 50%;
    transform: translateY(-50%);

    svg {
      @include transition();

      path {
        transform-origin: center;

        @include transition();
      }
    }

    @include teq-breakpoint($large) {
      display: none;
    }
  }

  .cart-mobile {
    left: $margin*2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include teq-breakpoint($large) {
      display: none;
    }
  }

  &.scrolled {
    transform: translateY(-100%) !important;
  }

  &.active-mobile {
    @include teq-breakpoint(0, $large) {
      border-color: white;
    }

    .nav-container {
      @include teq-breakpoint(0, $large) {
        opacity: 1 !important;
        transform: translateX(0%);
      }
    }

    .burger-mobile {
      svg {
        filter: invert(1);

        path {
          &:first-child {
            transform: rotate(45deg) translate(-1px, 9px);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(-45deg) translate(0, -9px);
          }
        }
      }
    }

    .cart-mobile {
      svg {
        filter: invert(1);
      }
    }

    &::after {
      background-color: white;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
}


