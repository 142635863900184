.block-contest{
    background-color: #F1F1F1;
    position: relative;
    padding-top: 103px;
    padding-bottom: 103px;
    overflow: hidden;
    margin-top: -80px;
    .top{
        z-index: 1;
        position: relative;
        .sub-title{
            margin: 42px auto;
        }

        .content{
            max-width: 954px;
            margin: 0 auto;
            p{
                font-size:16px;
                line-height: 24px;
                font-weight: 400;
                @include teq-breakpoint($large){
                    font-size: 22px;
                    line-height: 33px;
                }


            }
        }
    }

    .bottom{
        z-index: 1;
        position: relative;
        .cover{
            a{
                max-width: 500px;
                margin: 18px auto;
                display: inline-block;
            }
        }

        .content{
            p{
                margin: 61px auto;
            }
            a{
                width:308px;
                background-color: black;
                color:white;
                text-transform: uppercase;
                font-size: 17px;
                font-weight: 700;
                padding: 22px 22px 19px 22px;
                display: inline-block;
            }
        }
    }

    .illus{
        position: absolute;
        left:0px;
        top:0px;
        width: 100%;
        height:100%;
        pointer-events: none;
        z-index: 0;
        >svg{
          position: absolute;
            &:first-child{
                left:0px;
                top:0px;
                height:100%;
            }
            &:nth-child(2){
                right:0px;
                top:0%;
                height:100%;
            }
            &:nth-child(3){
                bottom:0%;
                left:50%;
                transform: translate(-50%, 50%);
            }
        }
    }

}
