.block-big-text {
  margin-bottom: $margin*4;
  margin-top: $margin*4;
  position: relative;

  @include teq-breakpoint($large) {
    margin-bottom: $margin*9;
    margin-top: $margin*20;
  }

  .text {
    max-width: 1064px;
  }


  &.block-big-text-illu {
    margin-bottom: $margin*9;
    margin-top: $margin*9;
    min-height: 60vw;

    .text {
      max-width: 621px;
    }

    .illus {
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;



      svg {
        max-width: 250px;
        width: 50%;

        @include teq-breakpoint($large) {
          width: auto;
        }
      }
    }
  }

  .deco {
    @include teq-breakpoint(0,$medium) {
      z-index: -1;
    }
  }
}
