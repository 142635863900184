.block-product-list {
  margin: $margin*4 auto;
  position: relative;

  @include teq-breakpoint($large) {
    margin: 0 auto;
  }

  >.row {
    margin: 0 auto;
    max-width: 1280px;
    position: relative;

    >svg {
      display: none;
      height: 44%;
      pointer-events: none;
      position: absolute;
      top: 9%;
      width: 100%;
      z-index: 0;

      @include teq-breakpoint($large) {
        display: block;
      }
    }
  }

  .top {
    svg {
      max-width: 500px;
      width: calc(100% - 80px);

      @include teq-breakpoint($large) {
        width: 35%;
      }
    }
  }

  .center {
    text-align: center;

    @include teq-breakpoint($large) {
      margin: $margin*8 auto $margin*6;
      text-align: left;
    }
  }

  .bottom {
    //overflow: hidden;
    padding: 0;
    position: relative;
    z-index: 2;

    >div.row {
      flex-wrap: nowrap;
      text-align: center;

      @include teq-breakpoint($large) {
        justify-content: center;
      }
    }

    @include teq-breakpoint($large) {
      overflow: inherit;
    }

    .product {
      flex: 0 0 60%;
      padding: 0;
      position: relative;
      width: 60%;

      &:first-child {
        margin-left: 20%;
      }

      @include teq-breakpoint($large) {
        flex: 0 0 33.33333%;
        width: 33.33333%;

        &:first-child {
          margin-left: 0;
        }
      }

      .imgs {
        margin: 0 auto;
        position: relative;

        @include teq-breakpoint($large) {
          max-width: 200px;
        }

        .blurb {
          bottom: 0;
          content: '';
          display: block;
          padding-bottom: 100%;
          position: absolute;
          transform: translateY(20%)  scale(0.6);
          width: 100%;

          @include transition(all,1s,cubic-bezier(0.68, -0.6, 0.32, 1.6));

          @include teq-breakpoint($large) {
            bottom: auto;
            position: relative;
            transform: scale(0.6);
          }

          svg {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
          }
        }

        .bottle {
          opacity: 0;
          position: relative;
          transform-origin: center center;
          z-index: 2;

          @include transition(all, 1s, cubic-bezier(0.34, 2.5, 0.64, 1),0.4s);

          @include teq-breakpoint($large) {
            bottom: 35%;
            left: 50%;
            position: absolute;
            transform: translateX(-50%) scale(0.8);
          }

          img {
            margin: 0 auto;
            width: 33%;

            @include teq-breakpoint($large) {
              width: 90%;
            }
          }
        }
      }

      .content {
        margin-top: 30%;

        @include teq-breakpoint(0,$large) {
          margin-left: -10%;
          width: calc(120%);
        }

        @include teq-breakpoint($large) {
          // bottom: 0;
          margin-top: 0;
          // position: absolute;
          // transform: translateY(100%);
        }

        h3 {
          svg {
            margin: 0 auto;
            width: 160px;

            path {
              @include transition();

              @include teq-breakpoint(0,$large) {
                &.rose {
                  stroke: #d09797;
                }

                &.blanc {
                  stroke: #f6eedb;
                }

                &.rouge {
                  stroke: #922323;
                }
              }
            }
          }
        }

        .content-hover {
          overflow: hidden;

          >div {
            opacity: 0;

            @include teq-breakpoint($large) {
              transform: translateY(30px);
            }

            @include transition(all,1s,cubic-bezier(0.25, 1, 0.5, 1),0s);
          }
        }
      }

      &:hover {
        @include teq-breakpoint($large) {
          .imgs {
            .blurb {
              transform: scale(0.9);
            }

            .bottle {
              opacity: 1;
              transform: translateX(-50%) scale(1);
            }
          }

          .content {
            h3 {
              svg {
                path {
                  stroke-width: 4px;
                  transform: translateY(2px);

                  &.rose {
                    stroke: #d09797;
                  }

                  &.blanc {
                    stroke: #f6eedb;
                  }

                  &.rouge {
                    stroke: #922323;
                  }
                }
              }
            }

            .content-hover {
              > div {
                opacity: 1;
                transform: translateY(0);

                @include transition(all, 1s, cubic-bezier(0.25, 1, 0.5, 1), 0.4s);
              }
            }
          }
        }
      }

      &.active {
        @include teq-breakpoint(0, $large) {
          .imgs {
            .blurb {
              transform: translateY(20%) scale(0.8);
            }

            .bottle {
              opacity: 1;
            }
          }

          .content {
            .content-hover {
              > div {
                opacity: 1;
                transform: translateY(0);
              }
            }
          }
        }
      }
    }
  }

  .deco {
    display: none;
    z-index: -1;

    @include teq-breakpoint($large) {
      display: block;
      z-index: 2;
    }
  }

  .nav {
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 58%;
    width: 100%;
    z-index: 5;

    > {
      div {
        pointer-events: all;
        position: absolute;

        &.prev {
          display: none;
          left: 30px;
        }

        &.next {
          right: 30px;
        }
      }
    }

    @include teq-breakpoint($large) {
      display: none;
    }
  }
}


.products-c-c {
  .product-content-item {
    flex: 0 0 60%;
    padding: 0;
    position: relative;
    text-align: center;
    width: 60%;

    &:first-child {
      margin-left: 20%;
    }

    @include teq-breakpoint($large) {
      flex: 0 0 33.33333%;
      width: 33.33333%;

      &:first-child {
        margin-left: 0;
      }
    }


    .content {
      margin-top: 30%;

      @include teq-breakpoint(0,$large) {
        margin-left: -10%;
        width: calc(120%);
      }

      @include teq-breakpoint($large) {
        bottom: 0;
        margin-top: 0;
      }

      h3 {
        svg {
          margin: 0 auto;
          width: 160px;

          path {
            @include transition();
          }
        }
      }

      .content-hover {
        overflow: hidden;

        >div {
          opacity: 0;

          @include teq-breakpoint($large) {
            transform: translateY(30px);
          }

          @include transition(all,1s,cubic-bezier(0.25, 1, 0.5, 1),0s);
        }
      }
    }



    &.active {
      @include teq-breakpoint($large) {
        .content {
          .content-hover {
            >div {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}
