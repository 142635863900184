// Inspired by https://github.com/nex3/sass/issues/408#issuecomment-6086901

$small:  30em !default; // ≈ 480px
$medium: 48em !default; // ≈ 768px
$large:  64em !default; // ≈ 1025px
$xlarge: 78.75em !default; // ≈ 1260px
$wide:   90em !default; // ≈ 1440px
$grid:   71.25em !default; // ≈ 1140px

$max-desktop: $large !default;

$media-queries: true !default; // true by default

@mixin teq-breakpoint($min-width: false, $max-width: false, $prefix: false) {
  @if $media-queries {
    @if $min-width {
      @if $max-width {
        @media all and (min-width: $min-width) and (max-width: $max-width) {
          @if $prefix {
            #{"." + $prefix} {
              @content;
            }
          }

          @else {
            @content;
          }
        }
      }

      @else {
        @media all and (min-width: $min-width) {
          @if $prefix {
            #{"." + $prefix} {
              @content;
            }
          }

          @else {
            @content;
          }
        }
      }
    }

    @else if $max-width {
      @media all and (max-width: $max-width) {
        @if $prefix {
          #{"." + $prefix} {
            @content;
          }
        }

        @else {
          @content;
        }
      }
    }
  }

  @else if $min-width and $min-width <= $max-desktop and
    (not $max-width or $max-width and $max-width >= $max-desktop) {
    @content;
  }
}
