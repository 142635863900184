/* TODO */
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: black;
  border: none;
  color: white;
  font-size: 12px;
  line-height: 18px;
  outline: none;
  padding: $margin*2 $margin*3;

  @include transition();

  &:hover {
    background-color: $grey-bg;
    color: black;
    cursor: pointer;
  }
}

.btn-nav {
  border: 1px solid black;
  display: inline-block;
  font-size: 10px;
  line-height: 10px;
  overflow: hidden;
  padding: $margin*2 $margin*5;
  position: relative;
  text-transform: uppercase;

  @include transition();
  @include John-bold();

  svg {
    @include transition();
  }

  >div {
    >span {
      @include transition();

      &:first-child {
        margin-right: $margin;
      }

      &:nth-child(2) {
        margin: 0 $margin;
      }
    }
  }

  &:hover {
    background-color: black;
    color: white;

    svg {
      filter: invert(1);
    }

    >div {
      >span {
        /* &:first-child {
          transform: translateX(35px);
        }

        &:nth-child(2) {
          transform: translateX(200px);
        }

        &:nth-child(3) {
          transform: translateX(200px);
        } */
      }
    }
  }
}
