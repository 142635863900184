

.hp-home-slider {
  //min-height: calc(100vh + 120px);

  position: relative;
  z-index: 4;

  @include teq-breakpoint($large) {

   // height: calc(100vh - 112px);
      min-height: 50vw;
  }


  .line-bg {
    background-color: $grey-border;
    content: '';
    display: none;
    height: 10%;
    left: 50%;
    padding: 0;
    position: absolute;
    top: 57%;
    transform: translate(-50%, -50%);
    width: 0;
    z-index: 1;

    @include teq-breakpoint($large) {
      display: block;
    }
  }

  .textes-container {
    left: 50%;
    opacity: 0;
    overflow: hidden;
    padding: $margin*2 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    z-index: 3;

    h3 {
      animation-duration: 20s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      font-size: 6vw;
      letter-spacing: 1vw;
      margin: 0;
      text-transform: uppercase;
      white-space: nowrap;

      @include teq-breakpoint($large) {
        margin: 0 0 $margin*2 0;
      }

      span {
        padding-right: $margin*3;
      }
    }

    .text-item {
      &:nth-child(1) {
        h3 {
          animation-name: anim-marquee;

          span {
            &:nth-child(2) {
              color: $grey-border;
            }

            &:nth-child(3) {
              color: $brown;
            }
          }
        }
      }

      &:nth-child(2) {
        h3 {
          animation-name: anim-marquee-invert;

          span {
            &:first-child {
              color: $grey-border;
            }

            &:nth-child(2) {
              color: $brown;
            }
          }
        }
      }
    }
  }

  .illu-container {
    opacity: 0;
    padding: 7% 0 0 0;

    >.illu {
      background-color: white;
      margin-top: $margin*12;
      width: 100%;
      z-index: 2;

      @include teq-breakpoint($large) {
        margin-top: 0;
        max-width: 700px;
        padding: $margin*4 $margin*4 0 $margin*4;
      }
    }

    svg {
      path {
        opacity: 0;
        transform-origin: center;
      }

      #illu-30 {
        opacity: 0;

        path {
          opacity: 1;
        }
      }
    }

    .illu-sub {
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      span {
        @include John-bold;
      }
    }

      .illu-zoom{
          text-align: center;
          @include teq-breakpoint($large){
              margin-top:20px;
          }
          svg{
              @include teq-breakpoint(0,$large){
                  width: 86px;
                  height:83px;
              }
              @include transition();
              &:hover{
                  transform: scale(1.1);
                  cursor:pointer;
              }
          }
      }
  }

  @keyframes anim-marquee {
    from { transform: translateX(0%); }
    to { transform: translateX(-100%); }
  }

  @keyframes anim-marquee-invert {
    from { transform: translateX(-100%); }
    to { transform: translateX(0%); }
  }
}

#overlay-intro {
  background-color: white;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}

body {
  &.loading {
    height: 100%;
    overflow: hidden;

    #overlay-intro {
      display: block;
    }
  }
}

/*
#CatEyes_open {
  path {
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: anim-cat-eyes;
    animation-timing-function: linear;
  }
}

@keyframes anim-cat-eyes {
  0% { height: block; }
  49.99% { display: block; }
  50% { display: none; }
  99.9999% { display: none; }
  100% { display: block; }
} */

#Soleil {
  transform: translateY(10px);
}



#popin-gallery{
    position: fixed;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    background-color:#F1F1F1;
    width: calc(100% - 36px);
    height: calc(100% - 36px);
    overflow: auto;
    display: none;
    z-index: 20;
    color:black;
    @include teq-breakpoint($large) {
        width: calc(100% - 80px);
        height: calc(100% - 80px);
    }
    // display: none;
    .popin-gallery-inner{
        display: flex;
        flex-wrap: wrap;
        padding: 24px;
        align-items: flex-start;
        height:100%;
        @include teq-breakpoint($large){
            align-items: stretch;
            padding: 36px;
        }
        >div{
            max-height: 100%;
            /*&.left{
                width: 100%;
                flex: 0 0 100%;
                @include teq-breakpoint($large){
                    width: 40%;
                    flex: 0 0 40%;
                    padding:0 4%;
                }

                display: flex;
                align-items: center;
                h4{
                    @include teq-breakpoint(0,$large) {
                        font-size:24px;
                        line-height:30px;
                    }

                    letter-spacing: 0.6rem;
                }
                p{
                    color:#8D8D8D;
                    font-size:18px;
                    line-height: 32px;
                    margin-top:24px;
                    @include teq-breakpoint($large){
                        margin-top:56px;
                    }
                }
            }*/
            &.right{
                width: 100%;
                flex: 0 0 100%;
                @include teq-breakpoint(0,$large) {
                    height: calc(100% - 60px);
                }
                >.right-inner{
                    position:relative;
                    background-color: white;
                    overflow: hidden;
                    height:100%;
                    >svg{
                        width:auto;
                        height: 110%;
                        transform: translate(-10%, -5%);
                        @include teq-breakpoint($large) {
                            width:120%;
                            height:auto;
                        }
                        .hotspot{
                            cursor: pointer;
                            touch-action: auto;
                            circle:nth-child(2){
                                pointer-events: none;
                            }
                        }
                    }
                }
                >.right-sub{
                    background-color: #F1F1F1;
                    padding-top: 8px;
                    text-align: center;
                    span{
                        font-size: 16px;
                    }
                }

                .infosHotSpots{
                    position: absolute;
                    left:0;
                    bottom:0;
                    width: calc(100%);
                    height:100%;
                    pointer-events: none;
                    @include teq-breakpoint($large) {
                        bottom:auto;
                        top:0px;
                        width: 100%;
                    }
                    >div{
                        background-color: #4F4F4F ;
                        opacity: 0;
                        @include transition(opacity);
                        position: absolute;
                        bottom:0;
                        left:0;
                        width: 100%;
                        pointer-events: none;
                        padding:20px;
                        @include teq-breakpoint(0,$large){
                            max-height: 200px;
                            overflow: auto;
                        }
                        @include teq-breakpoint($large) {
                            padding:34px 20px;
                            max-width: 400px;
                            bottom:auto;
                            transform: translate(-30%, 10px);
                        }
                        p{
                            color:white;
                            font-size: 16px;
                            line-height: 26px;
                            font-weight:300;
                            margin-bottom:0px;
                        }
                        &.infoHotSpotActive{
                            opacity: 1;

                            @include teq-breakpoint(0,$large){
                                pointer-events: all;
                            }
                        }

                    }
                }
            }

        }
    }
    #close-popin{
        position: absolute;

        cursor: pointer;
        @include transition();
        top:24px;
        right:24px;
        @include teq-breakpoint($large){
            top:56px;
            right:56px;
        }
        &:hover{
            transform: scale(1.06);
        }
    }
}
