/* TODO */
.arrow-hover {
  path {
    &:not(.hover-svg) {
      stroke: $txt-grey;
    }
  }

  &:hover {
    path {
      &:not(.hover-svg) {
        stroke: #000;
      }
    }
  }
}
