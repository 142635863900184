.block-big-marquee {
  margin: $margin*4 auto;
  padding: 0;
  position: relative;

  @include teq-breakpoint($large) {
    margin: $margin*9 auto;
  }

  .items-marquee-container {
    overflow: hidden;
    position: relative;

    .item-marquee {
      overflow: hidden;
      position: relative;
      white-space: nowrap;

      span {
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        color: $txt-grey-light;
        font-size: 30vw;
        line-height: 1;
        padding-right: 2vw;
        text-transform: uppercase;

        @include teq-breakpoint($large) {
          font-size: 15vw;
        }
      }

      &:first-child {
        z-index: 2;

        span {
          @include eaves;

          animation-name: anim-marquee;
        }
      }

      &:nth-child(2) {
        z-index: 0;

        span {
          @include John-medium;

          animation-name: anim-marquee-invert;
        }
      }
    }
  }

  .illu {
    bottom: -10%;
    display: none;
    position: absolute;
    right: 0;
    z-index: 1;

    @include teq-breakpoint($large) {
      display: block;
    }
  }

  @keyframes anim-marquee {
    from { transform: translateX(0%); }
    to { transform: translateX(-100%); }
  }
}
