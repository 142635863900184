#footer {
  .footer-share {
    background-color: $grey-bg;
    padding: $margin*6;

    ul {
      margin: 0;
      padding: 0;

      li {
        margin: 0 0;
          @include teq-breakpoint(0,$large) {
              padding: 0px 10px;
          }
        @include teq-breakpoint($large) {
          margin: 0 $margin*3;
        }
      }
    }
  }

  .footer-newsletter {
    background-color: $green-bg;
    color: white;
    padding: $margin*6;
    position: relative;

    .required {
      color: black;
      margin-top: $margin*4;

      >div {
        margin: 0 auto;
        max-width: 730px;
      }
    }

    input {
      width: calc(100% - 66px);

      @include teq-breakpoint($large) {
        width: 253px;
      }
    }

    .deco-footer {
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
        @include teq-breakpoint(0,$large) {
            display: none;
        }
      >div {
        position: absolute;

        &:first-child {
          left: 0;
          top: 0;
          transform: translateY(-50%);
        }


        &:nth-child(2) {
          @include teq-breakpoint(0,$large) {
            display: none;
          }

          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 30%;

          svg {
            width: 100%;
          }
        }
      }
    }
  }

  .footer-link {
    background-color: black;
    color: white;
    padding: $margin*2;

    a {
      color: white;
    }
  }

  .sib-form {
    padding: 0 !important;
  }

  .sib-form .entry__field {
    align-items: stretch;
    background: transparent !important;
    border: none;
    justify-content: center;

    input {
      &#EMAIL {
        background-color: white;
        border: none;
        color: black;
        padding: 5px 20px;
      }
    }
  }

  .sib-form-block {
    .entry__choice {
      margin: 0 auto;
      max-width: 730px;

      p {
        @include John;

        color: black;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .sib-form .entry__field:focus-within {
    box-shadow: none;
  }

  .copyright {
    text-align: right;

    a {
      color: white;
      font-size: 12px;
      line-height: 16px;
    }
  }
}



