.block-video-full {
  margin-top: 32px;
  padding: 0;
  position: relative;

  >div.row {
    margin: 0 auto;
    max-width: 1280px;

    &:first-child {
      position: relative;
    }

    @include teq-breakpoint(0,$large) {
      display: flex;
      min-height: 80vh;
    }

    video {
      @include teq-breakpoint(0,$large) {
        object-fit: cover;
      }

      padding: 0;
    }
  }

  .txt {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);

    @include teq-breakpoint($medium) {
      padding: 0 10%;
    }

    h4 {
      color: transparent;
      font-size: 25vw;
      line-height: 1;
      margin-bottom: 0;
      text-align: center;
      -webkit-text-stroke-color: white;
      -webkit-text-stroke-width: 2px;
      text-transform: uppercase;

      @include John-bold();

      @include teq-breakpoint($medium) {
        font-size: 14vw;
      }
    }

    p {
      color: white;
      font-size: 28px;
      letter-spacing: -5%;
      line-height: 32px;

      @include teq-breakpoint($medium) {
        font-size: 48px;
        line-height: 44.64px;
      }

      @include eaves;
    }
  }
    .deco{
        >div{
            &:first-child{
                @include teq-breakpoint(0,$large){
                    top:auto;
                    bottom: 0px;
                    transform: translateY(30px);
                }
            }
        }
    }
}
