/* ==========================================================================
    Main styles
   ========================================================================== */
// CONFIGURATION
@charset "UTF-8";

@import "config/vars";
@import "config/responsive";
@import "config/helpers";
@import "config/grid";

// LIBRARIES / PLUGINS


// PROJECT
@import "ui/colors";
@import "ui/typography";
@import "ui/anims";
@import "ui/table";
@import "ui/buttons";
@import "ui/icons";
@import "ui/forms";
@import "ui/layout";

// LIBRARIES / PLUGINS
@import "vendor/swiper";

// COMPONENTS
@import "components/global/header";
@import "components/global/footer";
@import "components/intro";

// HP
@import "components/hp/hp-block-title-full";
@import "components/hp/hp-home-slider";
@import "components/hp/hp-block-slider-history";
@import "components/hp/hp-history";
@import "components/hp/hp-block-video-full";
@import "components/hp/hp-block-gallery-slider";
@import "components/hp/hp-block-product-list";
@import "components/hp/hp-block-big-text";
@import "components/hp/hp-block-big-marquee";
@import "components/hp/hp-block-contest";
@import "components/hp/hp-art-gallery";

@import "ui/print";
