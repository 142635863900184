
.block-gallery-slider {
  margin: $margin*4 auto;
  position: relative;

  @include teq-breakpoint($large) {
    margin: $margin*9 auto;
  }

  small {
    color: #929292;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    text-transform: uppercase;
      padding-right: $margin;
      padding-top: $margin;
      @include teq-breakpoint($large){
          padding-right: $margin*4;
          padding-top: $margin*4;
      }

  }

  .swiper-gallery-slider {
    .swiper-slide {
      display: flex;
      padding: 0 $margin;
        width: 18.18%;
      >div {
        overflow: hidden;
        width: 100%;

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      &:first-child,
      &:nth-child(4) {
        align-items: flex-end;

        >div {
          height: 70%;
        }
      }

      &:nth-child(3) {
        align-items: flex-start;

        >div {
          height: 70%;
        }
      }
    }
  }

  >.illu {
    left: 35%;
    position: absolute;
    top: 0;

    svg {
      max-width: 70%;
    }
  }
}
