/* ==========================================================================
    Helpers & Objects
   ========================================================================== */

/* ==============================
    Helper classes
   ============================== */


/*
 * Clearfix
 */
.clearfix {
  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}


/*
 *  Blocks
 */
.fl-right {
  float: right;
}

.fl-left {
  float: left;
}


/*
 * Wordpress generated classes
 */
.alignnone,
.aligncenter,
.alignleft,
.alignright {
  display: block;
}

.wp-caption {
  position: relative;
  text-align: center; // center image for mobile
  .wp-caption-text {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    color: #fff;
    left: 0;
    margin: 0;
    padding: 5px 10px;
    position: absolute;
    right: 0;
    text-align: left; // left align caption
  }
}

@include teq-breakpoint($medium) {
  .alignnone,
  .aligncenter,
  .alignleft,
  .alignright {
    margin: 5px auto;
  }

  .alignnone {
    margin: 5px 20px 10px 0;
  }

  .aligncenter {
    display: block;
    margin: 5px auto;
    text-align: center;
  }

  .alignright {
    float: right;
    margin: 5px 0 10px 20px;
  }

  .alignleft {
    float: left;
    margin: 5px 20px 10px 0;
  }
}


// FontFace. GO to root/_fonts.scss to see it in action
@mixin declare-font-face($font-family, $font-filename, $font-weight : normal, $font-style :normal, $font-stretch : normal) {
  @font-face {
    font-display: swap;
    font-family: '#{$font-family}';
    font-stretch: $font-stretch;
    font-style: $font-style;
    font-weight: $font-weight;
    src:
      url(#{$font-filename}.woff2) format('woff2'),
      url(#{$font-filename}.woff) format('woff');
  }
}


.h-100 {
  height: 100%;
}
