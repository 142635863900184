
$c-yellow-1: #fc3;

$c-fb: #48629b;
$c-twitter: #01a8e9;
$c-linkedin: #0e76a8;


$c-grey-1: #ccc;
$c-grey-2: #dadada;

//z-index
$z-header: 100;

// Margins
$margin : 0.5rem;

// Dynamic paths
$images-url: "./images";
$fonts-url : "~fonts";

