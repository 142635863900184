/* ==========================================================================
    Custom fonts
   ========================================================================== */
/*
 EXEMPLE : @include declare-font-face("Galliard", "#{$fonts-url}/GalliardEF/GalliardEF-Roman");
 */
@import "https://use.typekit.net/mrl8vtj.css"; @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@include declare-font-face("JohnstonITCStd-Light", "#{$fonts-url}/JohnstonITCStd-Light");
@include declare-font-face("JohnstonITCStd-Medium", "#{$fonts-url}/johnstonitcstd-medium");
@include declare-font-face("JohnstonITCStd-Bold", "#{$fonts-url}/johnston-itc-std-bold");


@mixin eaves(){
    font-family:"mrs-eaves-roman-lining";
    font-weight: 400;
}
.eaves{
   @include eaves;
}

@mixin John(){
    font-family: "JohnstonITCStd-Light";
}
@mixin John-medium(){
    font-family: "JohnstonITCStd-Medium";
}
@mixin John-bold(){
    font-family: "JohnstonITCStd-Bold";
}
.john{
    @include John;
    &.medium{
        font-family: "JohnstonITCStd-Medium";
    }
    &.bold{
       @include  John-bold();
    }
}



// Font-stack shortcuts
$f-content:  'JohnstonITCStd-Light', sans-serif, Arial, Helvetica, "Liberation Sans", FreeSans;

/* ==========================================================================
    Default element styles
   ========================================================================== */


html,
button,
input,
select,
textarea {
  color: #333;
}

html {
  font: 1rem $f-content;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
}

* {
  -moz-font-smoothing: none;
  -webkit-font-smoothing: antialiased;
  /* stylelint-disable */
  font-smoothing: antialiased;
  /* stylelint-enable */

}


/* ==============================
    Typography
   ============================== */

/* "-like" classes should be used to maintain the semantically appropriate heading levels - NOT for use on non-headings */
h1,h2,h3,h4,h5,h6{
    margin: $margin*2 0;
}
h1,
.h1-like {
    /* TODO */
}

h2,
.h2-like {
  /* TODO */
}

h3,
.h3-like {
  /* TODO */
}

h4,
.h4-like {
  /* TODO */
}

h5,
.h5-like {
  /* TODO */
}

h6,
.h6-like {
  /* TODO */
}

p,
li {
  line-height: 1.8;
}

li li,
li p {
  font-size: 1rem;
}

p {
  margin: 0 0 2rem;
}

ul {
  margin: 0;
  padding-left: 3rem;

  ol,
  ul {
    margin: 0;

    li {
      margin: 0 0 1rem;
    }
  }
}

ol {
  padding-left: 2rem;

  ol,
  ul {
    margin: 0;

    li {
      margin: 0 0 1rem;
    }
  }

  li {
    margin: 0 0 2rem;
  }
}

ul li {
  list-style: none;
  margin: 0 0 2rem;
  padding-left: 1rem;
  position: relative;
}

.nobullets {
  list-style: none;
  padding-left: 0;

  li::before {
    display: none;
  }
}


a,
a:visited {
    color:$dark;
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  color: #000;
}

blockquote {
  /* TODO */
}


/* ==============================
    Other
   ============================== */

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

address {
  line-height: 1.5;
  margin-bottom: 1rem;
}

hr {
  background: rgba(255, 255, 255, 0.1);
  border: 0 none;
  height: 0.1rem;
  margin: 2rem 0;
}

::-moz-selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

.text-center{
    text-align: center;
}
.text-lg-center{
    @include teq-breakpoint($large){
        text-align: center;
    }
}

.text-uppercase{
    text-transform: uppercase;
}



// FONT-SIZE
.title-70{
    font-size: 40px;
    line-height: 44px;
    @include teq-breakpoint($large){
        font-size: 70px;
        line-height: 76px;
    }

}

.title-64{
    font-size:36px;
    line-height: 42px;
    @include teq-breakpoint($large) {
        font-size: 64px;
        line-height: 70px;
    }
}


.title-42{
    font-size:42px;
    line-height: 48px;
}

.title-32{
    font-size: 24px;
    line-height: 30px;
    @include teq-breakpoint($large) {
        font-size: 32px;
        line-height: 38px;
    }
}


.txt-22{
    font-size: 18px;
    line-height: 28px;
    @include teq-breakpoint($large) {
        font-size: 22px;
        line-height: 40px;
    }
}
.txt-18{
    font-size: 18px;
    line-height: 28px;
}

.txt-14{
    font-size: 14px;
    line-height: 20px;
}
.txt-12{
    font-size: 12px;
    line-height: 18px;
}


// LETTER SPACING
.l-s-6 {
    letter-spacing: 6px;
}
