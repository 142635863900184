/*!
 * Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

$include-column-box-sizing: true !default;

@import "../grid/functions";
@import "../grid/variables";
@import "../grid/mixins/lists";
@import "../grid/mixins/breakpoints";
@import "../grid/mixins/container";
@import "../grid/mixins/grid";
@import "../grid/mixins/utilities";
@import "../grid/vendor/rfs";
@import "../grid/containers";
@import "../grid/grid";
@import "../grid/utilities";
// Only use the utilities we need
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-get-multiple(
  $utilities,
  (
    "display",
    "order",
    "flex",
    "flex-direction",
    "flex-grow",
    "flex-shrink",
    "flex-wrap",
    "justify-content",
    "align-items",
    "align-content",
    "align-self",
    "margin",
    "margin-x",
    "margin-y",
    "margin-top",
    "margin-end",
    "margin-bottom",
    "margin-start",
    "negative-margin",
    "negative-margin-x",
    "negative-margin-y",
    "negative-margin-top",
    "negative-margin-end",
    "negative-margin-bottom",
    "negative-margin-start",
    "padding",
    "padding-x",
    "padding-y",
    "padding-top",
    "padding-end",
    "padding-bottom",
    "padding-start",
  )
);

@import "../grid/utilities/api";
