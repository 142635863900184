.section-art-gallery{
    background-color: $dark;
    padding-top:80px;
    color:white;
    @include teq-breakpoint($large){
        margin-top: -80px;
        padding-top:280px;
    }
    .intro{
        >div{
            >*{
                width: 100%;
            }
        }
        h3{
            letter-spacing: 10px;
            margin-bottom:0px;
        }
        h6{
            letter-spacing: 10px;
            margin-top: 0px;
            margin-bottom:42px;
            @include teq-breakpoint(0,$large) {
                font-size: 14px;
                letter-spacing: 2px;
                line-height: 20px;
            }
            span{
                background: -webkit-linear-gradient(#F3C59D, #C58466);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include teq-breakpoint(0,$large){
                    display: block;
                }
            }
        }
        .txt{
            @include teq-breakpoint($large){
                margin-top:40px;
                margin-bottom: 40px;
            }

        }
    }


    .gallery-pop{
        margin: 0 auto;
        max-width: 1280px;
        padding-bottom:80px;

        >h5{
            letter-spacing: 10px;
            text-align: center;
            margin-top:40px;
            margin-bottom: 20px;
            @include teq-breakpoint($large){
                margin-top:80px;
                padding-left:80px;
                margin-bottom: 80px;
                text-align: left;
            }
        }
        >.left{
            position: relative;
            .imgs{
                >div{
                    width:65%;
                    position: relative;
                    &:first-child{
                        z-index: 2;
                    }
                    &:nth-child(2){
                        z-index: 1;
                        margin-left: -30%;
                        margin-top:40%;
                    }
                    img{
                        width: 100%;
                    }


                    .view-more-gallery{
                        display: none!important;
                        position: absolute;
                        top:50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        @include transition();
                        transform-origin: center center;
                        &:hover{
                            cursor: pointer;
                            transform: translate(-50%,-50%) scale(1.1);
                        }
                        span{
                            position: absolute;
                            bottom:30px;
                            left:50%;
                            transform: translateX(-50%);
                            font-size:10px;
                            line-height: 9.41px;
                            text-transform: uppercase;
                            color:black;
                            white-space: nowrap;
                            pointer-events: none;
                        }
                    }
                }
            }
            svg.anim-svg{
                position:absolute;
                bottom:-80px;
                left:-80px;
                z-index: 3;
                width:100%;
                pointer-events: none;
            }


        }
        >.right{
            padding-top:40px;
            @include teq-breakpoint($large){
                padding-left:40px;
            }


        }
    }

    .gallery-quote{
        margin: 0 auto;
        max-width: 1280px;
        >.left{
            @include teq-breakpoint($large) {
                padding-right:90px;
            }
            p{
                @include teq-breakpoint($large){
                    line-height: 50px;
                }
            }
            >svg{
                margin-bottom:50px;
            }
        }
        >.right{
            padding: 0 40px;
            @include teq-breakpoint($large) {
                padding-left:90px;
            }
        }
    }

    .deco-gallery{
        padding-left: 0;
        margin-top:104px;
        z-index:2;
        position:relative;
        margin-bottom:70px;
        @include teq-breakpoint(0,$large){
            padding-left: 16px;
            padding-right: 16px;
        }
        svg{
            &:first-child{
                transform: translateY(-50%);
                position: absolute;
                left: 0px;
                top: 50%;
                @include teq-breakpoint(0,$large){
                    width: 50%;
                    top: auto;
                    bottom: 0px;
                    transform: translateY(87%);
                }
            }
        }
    }

    .swiper-gallery-c{
        margin-top: -40px;
        margin-bottom:0;
        padding: 0 16px;
        z-index: 1;
        @include teq-breakpoint($large){
            margin-top: $margin;
            padding: 0;
        }
        .swiper-slide{
            padding: 0;
            text-align:center;
            &.swiper-slide-prev, &.swiper-slide-next{
                cursor: pointer;
            }
            &:first-child{

            }

            img{
                transform-origin: center;
                @include transition();
                pointer-events: none;
            }
            video{
                transform-origin: center;
                @include transition();
                width: 100%;
            }
            &:not(.swiper-slide-active){
                img, video{
                    transform:scale(0.9);
                }
            }

            h6{
                pointer-events: none;
                font-size: 38px;
                line-height: 44px;
                @include  John-bold();
                margin-top:24px;
                margin-bottom:30px;
                opacity:0;
                @include transition();
                transition-delay: 0s;
                @include teq-breakpoint($large){
                    margin-top:42px;
                }
            }
            &.swiper-slide-active{
                h6{
                    transition-delay: 0.3s;
                    opacity: 1;
                }
            }
        }
        .swiper-gallery-nav{
            margin: 0 0 6rem 0%;
            width: 100%;
            >div {
                pointer-events: all;
                svg{
                    display: block;
                }
                &.count {
                    margin: 0 $margin;
                    white-space: nowrap;
                    span:nth-child(2) {
                        margin: 0 $margin;
                    }
                }

                &:not(.count) {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

    }
    .swiper-gallery-txts{
        padding: 0 20px;
        margin-bottom: $margin*10;
        @include teq-breakpoint($large){
            padding: 0 80px;
        }
        >div{
            color:white;
            max-width:900px;
            margin: 0 auto;
            display: none;
            opacity:0;
            transform: translateY(50px);
            &.active{
                display: block;
            }
            &:first-child{
                opacity:1;
                transform: translateY(0px);
            }

        }
    }

    .gallery-bottom{
        margin: 0 auto;
        max-width: 1280px;
        padding-bottom:$margin*10;
        >img{
            margin-bottom: $margin*3;
            max-width:770px;
            width: 100%;
        }
        a{
            margin-top: $margin*3;
            margin-bottom: $margin*3;
        }
    }
}

