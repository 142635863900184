#intro-page {
  background-color: white;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  .svg-container {
    height: 80%;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;

    svg {
      height: 100%;
      transform: scale(2);
    }
  }

  .textes-container {
    left: 50%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    .text-item {
      white-space: nowrap;

      &:nth-child(odd) {
        h3 {
          animation-name: anim-marquee;

          span {
            &:first-child {
              color: #d0d0d0;
            }

            &:nth-child(3) {
              color: #c69d44;
            }
          }
        }
      }

      &:nth-child(even) {
        h3 {
          animation-name: anim-marquee-invert;
          transform: translateX(-100%);

          span {
            &:nth-child(2) {
              color: #d0d0d0;
            }

            &:nth-child(3) {
              color: #c69d44;
            }
          }
        }
      }
    }

    h3 {
      animation-duration: 10s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      display: inline-block;
      font-size: 2.2vw;
      letter-spacing: 1vw;
      line-height: 1;
      margin-bottom: 0;
      padding-right: 40px;
      text-transform: uppercase;
    }
  }

  @keyframes anim-marquee {
    from { transform: translateX(0%); }
    to { transform: translateX(-100%); }
  }

  @keyframes anim-marquee-invert {
    from { transform: translateX(-100%); }
    to { transform: translateX(0%); }
  }
}
