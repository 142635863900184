/* ==========================================================================
    Print
   ========================================================================== */

@media print {
  /*
    * Boilerplate print styles
    */

  * {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important; /* Black prints faster: h5bp.com/s */
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  /* Don't show links for logo, images, or javascript/internal links */
  .logo a::after,
  .ir a::after,
  a[href^="javascript:"]::after,
  a[href^="#"]::after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; /* h5bp.com/t */
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .img-left .logo,
  .logo img {
    float: none;
    height: 67px;
  }

  .logo a {
    display: block;
  }

  .page {
    margin: 0;
  }

  .layout-2col::after,
  .sidebar,
  .bottom,
  .header::before,
  .slider,
  .nav-tactile,
  .menu,
  .fu,
  .footer {
    display: none !important;
  }

  .layout-2col > .primary {
    float: none;
    padding-right: 0;
    width: 100%;
  }
}
